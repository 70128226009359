import styles from './Contact.module.scss'
import PropTypes from 'prop-types'
import React from 'react'

const Contact = (props) => {
  const { postalAddress, phoneNumber, emailAddress } = props

  return (
    <div className={styles.Main}>
      <div className={styles.Text} dangerouslySetInnerHTML={{ __html: postalAddress.html }}/>
      <div>
        <div className={styles.Text} x-ms-format-detection="none"
             dangerouslySetInnerHTML={{ __html: phoneNumber.html }}/>
        <div className={styles.Text} dangerouslySetInnerHTML={{ __html: emailAddress.html }}/>
      </div>
    </div>
  )
}

export default Contact

Contact.propTypes = {
  postalAddress: PropTypes.object.isRequired,
  phoneNumber: PropTypes.object.isRequired,
  emailAddress: PropTypes.object.isRequired
}