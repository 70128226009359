import React from 'react'
import {graphql} from 'gatsby'
import {Redirect} from '@reach/router'
import Layout from '../components/layout/Layout'
import Headline from '../components/headline/Headline'
import Contact from '../components/contact/Contact'
import Draggable from '../components/draggable/Draggable'
import Button from '../components/button/Button'
import Helmet from 'react-helmet'


const Page = ({data: {contact}, pageContext}) => {

    if (!contact) return <Redirect to={`/${pageContext.locale}/404`} noThrow/>

    const {headline, postal_address, phone_number, email_address, button} = contact.data

    let draggableButton
    if (button && button.html) {
        const href = button.html.match(/href="([^"]*)/)

        draggableButton = (
            <Draggable
                left={Math.random()}
                top={Math.random()}
            >
                <Button to={href ? href[1] : '#'}>{button.text}</Button>
            </Draggable>
        )
    } else {
        draggableButton = null
    }

    return (
        <Layout
            locale={pageContext.locale}
            lang={pageContext.lang}
            alternatePath={pageContext.alternatePath}
            alternateLocale={pageContext.alternateLocale}
        >
            <Helmet>
                <title>{`Aquilas — ${headline.text}`}</title>
            </Helmet>

            <Headline headline={headline}/>
            <Contact
                postalAddress={postal_address}
                phoneNumber={phone_number}
                emailAddress={email_address}
            />
            {draggableButton}
        </Layout>
    )
}

export const query = graphql`
query contactProfilesQuery($lang: String!)  {
  contact: prismicContact(lang:{eq:$lang }) {
    data {
      headline {
        text
      }
      postal_address {
        html
      }
      phone_number {
        html
      }
      email_address {
        html
      }
      button {
        html
        text
      }
    }
  }
}
`

export default Page